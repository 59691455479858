import React, { Component } from 'react';
import {
	IFeedbackProps,
	IFeedbackState,
	FeedbackType,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ButtonFlat from '../App/styledComponents/ButtonFlat';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import InfoMessage from '../App/styledComponents/InfoMessage';
import SuccessMessage from '../App/styledComponents/SuccessMessage';
import WarningMessage from '../App/styledComponents/WarningMessage';
import IconWrapper from './styledComponents/IconWrapper';
import Icon from './styledComponents/Icon';
import MessageWrapper from './styledComponents/MessageWrapper';

@inject('appStore', 'feedbackStore')
@observer
export class Feedback extends Component<IFeedbackProps, IFeedbackState> {
	static configurationKey: 'feedback' = 'feedback';
	static styleKey: 'feedback' = 'feedback';
	constructor(props: IFeedbackProps) {
		super(props);
	}
	render() {
		const { className, feedbackStore } = this.props;

		if (!feedbackStore) {
			return null;
		}
		const { backButtonText, text, icon, back, type } = feedbackStore;
		let Message = InfoMessage;
		switch (type) {
			case FeedbackType.error:
				Message = ErrorMessage;
				break;
			case FeedbackType.info:
				Message = InfoMessage;
				break;
			case FeedbackType.success:
				Message = SuccessMessage;
				break;
			case FeedbackType.warning:
				Message = WarningMessage;
				break;
		}
		return (
			<Wrapper className={ className }>
				<ButtonFlat onClick={ back } iconProps={ { asset: 'base.icons.backArrow' } } iconPosition={ 'left' }>
					{ backButtonText }
				</ButtonFlat>
				<IconWrapper>
					<Icon asset={ icon } />
				</IconWrapper>
				<MessageWrapper>
					<Message>{ text }</Message>
				</MessageWrapper>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Feedback, 'feedback');

