import { computed } from 'mobx';
import { GetApplicationAppConfig } from '@kurtosys/ksys-api-client/dist/requests/root/GetApplicationAppConfig';
import { ListTranslations } from '@kurtosys/ksys-api-client/dist/requests/config/ListTranslations';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';
import { ListCommentaries } from '@kurtosys/ksys-api-client/dist/requests/config/ListCommentaries';
import { ListDisclaimers } from '@kurtosys/ksys-api-client/dist/requests/config/ListDisclaimers';
import { SearchDocuments } from '@kurtosys/ksys-api-client/dist/requests/documents/SearchDocuments';
import { SearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SearchEntity';
import { GetTheme } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/GetTheme';
import { RetrieveClientConfiguration } from '@kurtosys/ksys-api-client/dist/requests/config/RetrieveClientConfiguration';
import { SavedSearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SavedSearchEntity';
import { RequestStrategies } from '@kurtosys/ksys-api-client/dist/requests/auth/RequestStrategies';
import { Login2FAProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/Login2FAProxy';
import { LoginProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/LoginProxy';
import { SAMLSignIn } from '@kurtosys/ksys-api-client/dist/requests/auth/SAMLSignIn';
import { Assurance } from '@kurtosys/ksys-api-client/dist/requests/auth/Assurance';
import { RetrieveDocument } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocument';
import { Retrieve } from '@kurtosys/ksys-api-client/dist/requests/documents/Retrieve';
import { RetrieveDocumentZip } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocumentZip';
import { PasswordReset } from '@kurtosys/ksys-api-client/dist/requests/auth/PasswordReset';
import { UsernameRequest } from '@kurtosys/ksys-api-client/dist/requests/auth/UsernameRequest';
import { UpdatePasswordProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/UpdatePasswordProxy';
import { PasswordRequirements } from '@kurtosys/ksys-api-client/dist/requests/auth/PasswordRequirements';
import { Get2FAMethods } from '@kurtosys/ksys-api-client/dist/requests/auth/Get2FAMethods';
import { ValidateCode } from '@kurtosys/ksys-api-client/dist/requests/auth/ValidateCode';
import { GetUserByToken } from '@kurtosys/ksys-api-client/dist/requests/auth/getUserByToken';
import { AssuranceImages } from '@kurtosys/ksys-api-client/dist/requests/auth/AssuranceImages';
import { AssuranceUpdate } from '@kurtosys/ksys-api-client/dist/requests/auth/AssuranceUpdate';
import { MfaEnroll } from '@kurtosys/ksys-api-client/dist/requests/auth/MfaEnroll';
import { MfaEnrollAuth } from '@kurtosys/ksys-api-client/dist/requests/auth/MfaEnrollAuth';
import { MfaLoginEnroll } from '@kurtosys/ksys-api-client/dist/requests/auth/MfaLoginEnroll';
import { MfaLoginEnrollAuth } from '@kurtosys/ksys-api-client/dist/requests/auth/MfaLoginEnrollAuth';
import { KurtosysApiStoreBase } from './KurtosysApiStoreBase';
import { GetApplicationClientConfiguration } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/GetApplicationClientConfiguration';
import { GetFilters } from '@kurtosys/ksys-api-client/dist/requests/fund/GetFilters';
import { models } from '@kurtosys/ksys-app-template';

export class KurtosysApiStore extends KurtosysApiStoreBase {
	@computed
	get client(): IQueryContextClient {
		const response: IQueryContextClient = {
			config: {
				clientConfiguration: {
					retrieve: this.createEndpoint(RetrieveClientConfiguration),
				},
			},
			commentaries: {
				list: this.createEndpoint(ListCommentaries),
			},
			disclaimers: {
				list: this.createEndpoint(ListDisclaimers),
			},
			documents: {
				search: this.createEndpoint(SearchDocuments),
				retrieve: this.createEndpoint(RetrieveDocument),
				retrieveByProperty: this.createEndpoint(Retrieve),
				retrieveZip: this.createEndpoint(RetrieveDocumentZip),
			},
			entity: {
				search: this.createEndpoint(SearchEntity),
				savedSearchEntity: this.createEndpoint(SavedSearchEntity),
			},
			options: {
				baseUrl: this.serviceUrl,
			},
		};
		return response;
	}

	@computed
	get getAppConfig(): GetApplicationAppConfig {
		return this.createEndpoint(GetApplicationAppConfig);
	}

	@computed
	get listTranslations(): ListTranslations {
		return this.createEndpoint(ListTranslations);
	}

	@computed
	get getTheme(): GetTheme {
		return this.createEndpoint(GetTheme);
	}

	@computed
	get requestStrategies(): RequestStrategies {
		return this.createEndpoint(RequestStrategies, { isProxy: true });
	}

	@computed
	get assurance(): Assurance {
		return this.createEndpoint(Assurance, { isProxy: true });
	}

	@computed
	get assuranceImages(): AssuranceImages {
		return this.createEndpoint(AssuranceImages, { isProxy: true });
	}

	@computed
	get assuranceUpdate(): AssuranceUpdate {
		return this.createEndpoint(AssuranceUpdate, { isProxy: true });
	}

	@computed
	get login(): LoginProxy {
		return this.createEndpoint(LoginProxy, { isProxy: true });
	}

	@computed
	get samlSignIn(): SAMLSignIn {
		return this.createEndpoint(SAMLSignIn, { isProxy: true });
	}

	@computed
	get login2FA(): Login2FAProxy {
		return this.createEndpoint(Login2FAProxy, {
			isProxy: true,
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie
			}
		});
	}

	@computed
	get passwordReset(): PasswordReset {
		return this.createEndpoint(PasswordReset, { isProxy: true });
	}

	@computed
	get usernameRequest(): UsernameRequest {
		return this.createEndpoint(UsernameRequest, { isProxy: true });
	}

	@computed
	get passwordRequirements(): PasswordRequirements {
		return this.createEndpoint(PasswordRequirements, { isProxy: true });
	}

	@computed
	get validateCode(): ValidateCode {
		return this.createEndpoint(ValidateCode, { isProxy: true });
	}

	@computed
	get updatePassword(): UpdatePasswordProxy {
		return this.createEndpoint(UpdatePasswordProxy, { isProxy: true });
	}

	@computed
	get getUserByToken(): GetUserByToken {
		return this.createEndpoint(GetUserByToken, {
			isProxy: true,
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			}
		});
	}

	@computed
	get getApiUserByToken(): GetUserByToken {
		return this.createEndpoint(GetUserByToken, {
			isProxy: true,
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.header,
			}
		});
	}

	get get2FAMethods(): Get2FAMethods {
		return this.createEndpoint(Get2FAMethods, {
			isProxy: true,
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			}
		});
	}

	@computed
	get mfaEnroll(): MfaEnroll {
		return this.createEndpoint(MfaEnroll, { isProxy: true });
	}

	@computed
	get mfaEnrollAuth(): MfaEnrollAuth {
		return this.createEndpoint(MfaEnrollAuth, { isProxy: true });
	}

	@computed
	get mfaLoginEnroll(): MfaLoginEnroll {
		return this.createEndpoint(MfaLoginEnroll, {
			isProxy: true,
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			}
		});
	}

	@computed
	get mfaLoginEnrollAuth(): MfaLoginEnrollAuth {
		return this.createEndpoint(MfaLoginEnrollAuth, {
			isProxy: true,
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			}
		});
	}

	get getApplicationClientConfiguration(): GetApplicationClientConfiguration {
		return this.createEndpoint(GetApplicationClientConfiguration);
	}

	@computed
	get getFilters(): GetFilters {
		return this.createEndpoint(GetFilters);
	}
}
