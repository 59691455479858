import React, { Component } from 'react';
import {
	IPasswordValidationProps,
	IPasswordValidationState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Title from './styledComponents/Title';
import Rules from './styledComponents/Rules';
import Rule from './styledComponents/Rule';
import RuleText from './styledComponents/RuleText';
import RuleIcon from './styledComponents/RuleIcon';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';

@inject('appStore', 'passwordValidationStore')
@observer
export class PasswordValidation extends Component<IPasswordValidationProps, IPasswordValidationState> {
	static configurationKey: 'passwordValidation' = 'passwordValidation';
	static styleKey: 'passwordValidation' = 'passwordValidation';
	constructor(props: IPasswordValidationProps) {
		super(props);
	}
	render() {
		const { className, passwordValidationStore } = this.props;

		if (!passwordValidationStore) {
			return null;
		}
		const { title, passwordCriteria } = passwordValidationStore;
		return (
			<Wrapper className={ className }>
				<Title>
					<Translate>{ title }</Translate>
				</Title>
				<Rules>
					{ passwordCriteria.map(criterion => (
						<Rule key={ criterion.rule }>
							<RuleIcon src={ criterion.valid ? 'Tick.svg' : 'Cross.svg' }>
							</RuleIcon>
							<RuleText>
								<Translate>{ criterion.message }</Translate>
							</RuleText>
						</Rule>
					)) }
				</Rules>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(PasswordValidation, 'passwordValidation');

