import React, { Component } from 'react';
import {
	IMfaMethodSelectorProps,
	IMfaMethodSelectorState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import FieldWrapper from './styledComponents/FieldWrapper';
import Label from './styledComponents/Label';
import ButtonWrapper from './styledComponents/ButtonWrapper';
import Button from './styledComponents/Button';
import Title from './styledComponents/Title';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';

@inject('appStore', 'mfaMethodSelectorStore')
@observer
export class MfaMethodSelector extends Component<IMfaMethodSelectorProps, IMfaMethodSelectorState> {
	static configurationKey: 'mfaMethodSelector' = 'mfaMethodSelector';
	static styleKey: 'mfaMethodSelector' = 'mfaMethodSelector';
	constructor(props: IMfaMethodSelectorProps) {
		super(props);
	}

	render() {
		const { className, mfaMethodSelectorStore } = this.props;

		if (!mfaMethodSelectorStore) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<Title title={ 'Two-Factor Authentication' } level={ 3 } />
				<FieldWrapper>
					<Label id="label_pushAuthentication" htmlFor="button_pushAuthentication">
						<Translate>Push Authentication</Translate>
					</Label>
					<ButtonWrapper>
						<Button id="button_pushAuthentication" aria-describedBy="label_pushAuthentication" onClick={ () => mfaMethodSelectorStore.startPushActivation() }>
							<Translate>CONFIGURE</Translate>
						</Button>
					</ButtonWrapper>
				</FieldWrapper>
				<FieldWrapper>
					<Label id="label_textAuthentication" htmlFor="button_textAuthentication">
						<Translate>Text Authentication</Translate>
					</Label>
					<ButtonWrapper>
						<Button id="button_textAuthentication" aria-describedBy="label_textAuthentication" onClick={ () => mfaMethodSelectorStore.startTextActivation() }>
							<Translate>CONFIGURE</Translate>
						</Button>
					</ButtonWrapper>
				</FieldWrapper>
				<FieldWrapper>
					<Label id="label_callAuthentication" htmlFor="button_callAuthentication">
						<Translate>Call Authentication</Translate>
					</Label>
					<ButtonWrapper>
						<Button id="button_callAuthentication" aria-describedBy="label_callAuthentication" onClick={ () => mfaMethodSelectorStore.startCallActivation() }>
							<Translate>CONFIGURE</Translate>
						</Button>
					</ButtonWrapper>
				</FieldWrapper>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(MfaMethodSelector, 'mfaMethodSelector');

