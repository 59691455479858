import { TMfaStep } from '../../../models/app/TMfaStep';
import { TLoginStep } from '../../../models/app/TLoginStep';

export const config: { loginSteps: { [key: string]: TLoginStep; }; mfaSteps: { [key: string]: TMfaStep; } } = {
	loginSteps: {
		USER_LOGIN: 'UserLogin' as TLoginStep,
		USER_LOGIN_2FA: 'UserLogin2FA' as TLoginStep,
		LOGIN_STRATEGY_SELECTOR: 'LoginStrategySelector' as TLoginStep,
		RESET_PASSWORD: 'ResetPassword' as TLoginStep,
		FORGOT_USERNAME: 'ForgotUsername' as TLoginStep,
		ACCOUNT_LOCKED: 'AccountLocked' as TLoginStep,
		CODE_INVALID: 'CodeInvalid' as TLoginStep,
		ERROR: 'Error' as TLoginStep,
		USER_SUCCESS: 'UserSuccess' as TLoginStep,
		SET_PASSWORD: 'SetPassword' as TLoginStep,
		SETUP_ASSURANCES: 'SetupAssurances' as TLoginStep,
		MFA: 'MFA' as TLoginStep,
		MFA_SUCCESS: 'MFASuccess' as TLoginStep,
		MFA_SELECTOR: 'MFASelector' as TLoginStep,
	},
	mfaSteps: {
		METHOD_SELECT: 'MethodSelect' as TMfaStep,
		DUO_PUSH_SETUP: 'DuoPushSetup' as TMfaStep,
		DUO_TEXT_SETUP: 'DuoTextSetup' as TMfaStep,
		DUO_CALL_SETUP: 'DuoCallSetup' as TMfaStep,
		DUO_TEXT_CONFIRM: 'DuoTextConfirm' as TMfaStep,
		DUO_PUSH: 'DuoPush' as TMfaStep,
		DUO_PUSH_CONFIRM: 'DuoPushConfirm' as TMfaStep,
		DUO_BACKUP_ENTRY: 'DuoBackupEntry' as TMfaStep,
		DUO_BACKUP_PHONE: 'DuoBackupPhone' as TMfaStep,
		DUO_BACKUP_TEXT: 'DuoBackupText' as TMfaStep,
		DUO_BACKUP_TEXT_CONFIRM: 'DuoBackupTextConfirm' as TMfaStep,
	},
};