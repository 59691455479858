import React, { Component } from 'react';
import {
	ILoginStrategySelectorProps,
	ILoginStrategySelectorState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ButtonRaised from '../App/styledComponents/ButtonRaised';

@inject('appStore', 'loginStrategySelectorStore')
@observer
export class LoginStrategySelector extends Component<ILoginStrategySelectorProps, ILoginStrategySelectorState> {
	static configurationKey: 'loginStrategySelector' = 'loginStrategySelector';
	static styleKey: 'loginStrategySelector' = 'loginStrategySelector';
	constructor(props: ILoginStrategySelectorProps) {
		super(props);
	}

	render() {
		const { className, loginStrategySelectorStore, appStore } = this.props;

		if (!loginStrategySelectorStore || !appStore) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				{ loginStrategySelectorStore.loginStrategies &&
					loginStrategySelectorStore.loginStrategies.map((strategy, index) => {
						return (
							<ButtonRaised
								autoFocus={ index === 0 }
								key={ 'strategy-' + index }
								onClick={ () => {
									loginStrategySelectorStore.onAuthMethodSelect(strategy);
								} }
								data-qa-ref={ strategy.type.toLowerCase().replace(' ', '') + 'LoginStrategyButton' }
							>
								{ loginStrategySelectorStore.getStrategyButtonName(strategy.type, strategy.name) }
							</ButtonRaised>
						);
					}) }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(LoginStrategySelector, 'loginStrategySelector');

