import { Operator } from '@kurtosys/ksys-app-template/dist/models/common';
import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	culture: {
		base: 'Default',
		default: 'en-GB',
	},
	components: {
		app: {
			// cardProps: {
			// 	props: {
			// 		title: 'Welcome to the Portal',
			// 	},
			// },
		},
		mfaSetup: {
			backButtonIcon: {
				asset: 'base.icons.backArrow',
				accessibilityText: 'Back to two factor method selection',
			},
		},
		userLogin: {
			redirectUrl: {
				queryOptionsType: 'loggedInUser',
				options: {
					loggedInUser: {
						code: 'roles',
						key: 'role',
					},
				},
				transforms: [
					{
						transformOptionsType: 'switch',
						switchOptions: {
							cases: [
								{
									id: 'ClientAdmin',
									conditional: {
										conditions: [
											{
												operator: 'includes' as Operator,
												value: 'ClientAdmin',
											},
										],
									},
									response: '/client-admin',
								},
								{
									id: 'ApiLoaderUser',
									conditional: {
										conditions: [
											{
												operator: 'includes' as Operator,
												value: 'ApiLoaderUser',
											},
										],
									},
									response: '/api-loader-user',
								},
								{
									id: 'PortalUser',
									conditional: {
										conditions: [
											{
												operator: 'includes' as Operator,
												value: 'PortalUser',
											},
										],
									},
									response: '/portal-user',
								},
							],
							defaultResponse: '/',
						},
					},
				],
			},
			usernameLabelText: 'Username',
			passwordLabelText: 'Password',
		},
	},
};
