import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { MessageStore } from '../components/App/stores/MessageStore';
import { Manifest } from './Manifest';
import { LoginStrategySelectorStore } from '../components/LoginStrategySelector/stores/LoginStrategySelectorStore';
import { UserLoginStore } from '../components/UserLogin/stores/UserLoginStore';
import { AssuranceStore } from '../components/Assurance/stores/AssuranceStore';
import { ForgotPasswordStore } from '../components/ForgotPassword/stores/ForgotPasswordStore';
import { ForgotUsernameStore } from '../components/ForgotUsername/stores/ForgotUsernameStore';
import { SetPasswordStore } from '../components/SetPassword/stores/SetPasswordStore';
import { FeedbackStore } from '../components/Feedback/stores/FeedbackStore';
import { UserLogin2FaStore } from '../components/UserLogin2Fa/stores/UserLogin2FaStore';
import { LoginCodeStore } from '../components/LoginCode/stores/LoginCodeStore';
import { PasswordValidationStore } from '../components/PasswordValidation/stores/PasswordValidationStore';
import { SetupAssurancesStore } from '../components/SetupAssurances/stores/SetupAssurancesStore';
import { MfaSetupStore } from '../components/MfaSetup/stores/MfaSetupStore';
import { MfaSetupPushStore } from '../components/MfaSetupPush/stores/MfaSetupPushStore';
import { MfaSetupBackupStore } from '../components/MfaSetupBackup/stores/MfaSetupBackupStore';
import { PhoneNumberStore } from '../components/PhoneNumber/stores/PhoneNumberStore';
import { MfaMethodSelectorStore } from '../components/MfaMethodSelector/stores/MfaMethodSelectorStore';
import { AccessibilityStore } from '../components/App/stores/AccessibilityStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	queryStore: QueryStore;
	loginStrategySelectorStore: LoginStrategySelectorStore;
	messageStore: MessageStore;
	userLoginStore: UserLoginStore;
	assuranceStore: AssuranceStore;
	forgotPasswordStore: ForgotPasswordStore;
	forgotUsernameStore: ForgotUsernameStore;
	setPasswordStore: SetPasswordStore;
	feedbackStore: FeedbackStore;
	userLogin2FaStore: UserLogin2FaStore;
	loginCodeStore: LoginCodeStore;
	passwordValidationStore: PasswordValidationStore;
	setupAssurancesStore: SetupAssurancesStore;
	mfaSetupStore: MfaSetupStore;
	mfaSetupPushStore: MfaSetupPushStore;
	mfaSetupBackupStore: MfaSetupBackupStore;
	phoneNumberStore: PhoneNumberStore;
	mfaMethodSelectorStore: MfaMethodSelectorStore;
	accessibilityStore: AccessibilityStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, public manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.queryStore = new QueryStore(this);
		this.messageStore = new MessageStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.loginStrategySelectorStore = new LoginStrategySelectorStore(this);
		this.userLoginStore = new UserLoginStore(this);
		this.assuranceStore = new AssuranceStore(this);
		this.forgotPasswordStore = new ForgotPasswordStore(this);
		this.forgotUsernameStore = new ForgotUsernameStore(this);
		this.setPasswordStore = new SetPasswordStore(this);
		this.feedbackStore = new FeedbackStore(this);
		this.userLogin2FaStore = new UserLogin2FaStore(this);
		this.loginCodeStore = new LoginCodeStore(this);
		this.passwordValidationStore = new PasswordValidationStore(this);
		this.setupAssurancesStore = new SetupAssurancesStore(this);
		this.mfaSetupStore = new MfaSetupStore(this);
		this.mfaSetupPushStore = new MfaSetupPushStore(this);
		this.mfaSetupBackupStore = new MfaSetupBackupStore(this);
		this.phoneNumberStore = new PhoneNumberStore(this);
		this.mfaMethodSelectorStore = new MfaMethodSelectorStore(this);
		this.accessibilityStore = new AccessibilityStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	reInitialize() {
		this.appStore.reInitialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			queryStore: this.queryStore,
			messageStore: this.messageStore,
			loginStrategySelectorStore: this.loginStrategySelectorStore,
			userLoginStore: this.userLoginStore,
			assuranceStore: this.assuranceStore,
			forgotPasswordStore: this.forgotPasswordStore,
			forgotUsernameStore: this.forgotUsernameStore,
			setPasswordStore: this.setPasswordStore,
			feedbackStore: this.feedbackStore,
			userLogin2FaStore: this.userLogin2FaStore,
			loginCodeStore: this.loginCodeStore,
			passwordValidationStore: this.passwordValidationStore,
			setupAssurancesStore: this.setupAssurancesStore,
			mfaSetupStore: this.mfaSetupStore,
			mfaSetupPushStore: this.mfaSetupPushStore,
			mfaSetupBackupStore: this.mfaSetupBackupStore,
			phoneNumberStore: this.phoneNumberStore,
			mfaMethodSelectorStore: this.mfaMethodSelectorStore,
			accessibilityStore: this.accessibilityStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) {

	}
}