import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IPhoneNumberConfiguration } from '../models';

export class PhoneNumberStore extends StoreBase {
	static componentKey: 'phoneNumber' = 'phoneNumber';

	@computed
	get configuration(): IPhoneNumberConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(PhoneNumberStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

}