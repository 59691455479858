import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IUserLogin2FaConfiguration } from '../models';
import { config } from '../../App/models/config';

export class UserLogin2FaStore extends StoreBase {
	static componentKey: 'userLogin2Fa' = 'userLogin2Fa';
	@observable public mfaMethods = [];
	@observable public showtextEntry2FA: boolean = false;
	@observable hasSentCodeOnce = false;

	@computed
	get configuration(): IUserLogin2FaConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(UserLogin2FaStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get backButtonText(): string {
		return (this.configuration && this.getQueryValue(this.configuration.backButtonText)) || 'Select Strategy';
	}

	public getMethodButtonName = (method: any) => {
		return `${ method.type === 'PUSH' ? 'SEND DUO PUSH' : method.type } ${ method.phone ? `(${ method.phone })` : ''
			}`.toUpperCase();
	}

	@action
	public load2FASettings = async () => {
		const { appStore, kurtosysApiStore } = this.storeContext;
		const loadingKey = 'UserLogin2FaStore.load2FASettings';
		appStore.startLoading(loadingKey);
		const overrides = {
			disableCache: true,
		};
		const response = await kurtosysApiStore.get2FAMethods.callApi(overrides);
		if (response.status !== 200) {
			const message = await response.text();
			if (message.includes('2FA is not enabled for this user')) {
				appStore.setStep(config.loginSteps.MFA_SELECTOR);
				appStore.stopLoading(loadingKey);
				return false;
			}
			appStore.redirectTo(appStore.redirectURL);
		}
		const methods = await response.json();
		this.mfaMethods = methods;
		appStore.stopLoading(loadingKey);
		return true;
	}
}