import React, { Component } from 'react';
import {
	IMfaSetupProps,
	IMfaSetupState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import HeaderWrapper from './styledComponents/HeaderWrapper';
import Button from './styledComponents/Button';
import Heading from './styledComponents/Heading';
import { MfaStep } from './stores/MfaSetupStore';
import MfaSetupPush from '../MfaSetupPush';
import MfaSetupBackup from '../MfaSetupBackup';
import { config } from '../App/models/config';

@inject('appStore', 'mfaSetupStore')
@observer
export class MfaSetup extends Component<IMfaSetupProps, IMfaSetupState> {
	static configurationKey: 'mfaSetup' = 'mfaSetup';
	static styleKey: 'mfaSetup' = 'mfaSetup';
	constructor(props: IMfaSetupProps) {
		super(props);
	}

	render() {
		const { className, mfaSetupStore, appStore } = this.props;

		if (!appStore || !mfaSetupStore) {
			return null;
		}

		const { setStep } = appStore;


		const { step, headingProps, backButtonIcon } = mfaSetupStore;
		const { loginSteps, mfaSteps } = config;
		return (
			<Wrapper className={ className }>
				<HeaderWrapper>
					<Button
						iconProps={ backButtonIcon }
						onClick={ () => setStep(loginSteps.MFA_SELECTOR) }
					/>
					<Heading { ...headingProps }></Heading>
				</HeaderWrapper>
				{ step === mfaSteps.DUO_PUSH_SETUP && <MfaSetupPush /> }
				{ (step === mfaSteps.DUO_TEXT_SETUP || step === mfaSteps.DUO_CALL_SETUP) && <MfaSetupBackup /> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(MfaSetup, 'mfaSetup');

