import React, { Component } from 'react';
import {
	IUserLogin2FaProps,
	IUserLogin2FaState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ButtonFlat from '../App/styledComponents/ButtonFlat';
import LoginCode from '../LoginCode';

@inject('appStore', 'userLogin2FaStore', 'loginStrategySelectorStore')
@observer
export class UserLogin2Fa extends Component<IUserLogin2FaProps, IUserLogin2FaState> {
	static configurationKey: 'userLogin2Fa' = 'userLogin2Fa';
	static styleKey: 'userLogin2Fa' = 'userLogin2Fa';
	constructor(props: IUserLogin2FaProps) {
		super(props);
	}
	render() {
		const { className, userLogin2FaStore, appStore, loginStrategySelectorStore } = this.props;

		if (!userLogin2FaStore || !appStore || !loginStrategySelectorStore) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<ButtonFlat onClick={ appStore.clear } iconProps={ { asset: 'base.icons.backArrow' } } iconPosition={ 'left' }>
					{ userLogin2FaStore.backButtonText }
				</ButtonFlat>
				{ userLogin2FaStore.mfaMethods &&
					!userLogin2FaStore.showtextEntry2FA &&
					userLogin2FaStore.mfaMethods.map((method, index) => {
						return (
							<ButtonRaised
								autoFocus={ index === 0 }
								key={ 'method-' + index }
								onClick={ () => {
									loginStrategySelectorStore.onAuthMethodSelect(method);
								} }
							>
								{ userLogin2FaStore.getMethodButtonName(method) }
							</ButtonRaised>
						);
					}) }
				{ userLogin2FaStore.showtextEntry2FA && <LoginCode /> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(UserLogin2Fa, 'userLogin2Fa');

