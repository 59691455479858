import { allCountries } from 'country-telephone-data';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { utils } from '@kurtosys/ksys-app-template';

// Codes for:
// United States, Austria, Belgium, Germany, France, Netherlands, Italy,
// Switzerland, Denmark, Norway, Finland, Sweden, Spain, Portugal, Ireland.
const priorityCountries = ['us', 'at', 'be', 'de', 'fr', 'nl', 'it', 'ch', 'dk', 'no', 'fi', 'se', 'es', 'pt', 'ie'];

export const getCountryCodes = () => {
	const response: IDropdownItem[] = [];
	let countryCodes = [];
	let favouredCountries = [];
	let gb;
	for (const c of allCountries) {
		const dialCode = c.dialCode;
		const countryCode = c.iso2.toUpperCase();
		const dropdownItem: IDropdownItem = {
			label: `${ countryCode } +${ dialCode }`,
			value: `+${ dialCode }`,
		};
		if (c.iso2 === 'gb') {
			gb = dropdownItem;
		}
		else if (priorityCountries.includes(c.iso2)) {
			favouredCountries.push(dropdownItem);
		}
		else {
			countryCodes.push(dropdownItem);
		}
	}

	countryCodes = utils.collection.sortBy(countryCodes, c => c.label);
	favouredCountries = favouredCountries.sort();
	if (gb) {
		response.push(gb);
	}
	response.push(...favouredCountries, ...countryCodes);
	return response;
};
