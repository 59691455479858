import React, { Component } from 'react';
import {
	IAssuranceProps,
	IAssuranceState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import IconWrapper from './styledComponents/IconWrapper';
import IconComponent from './styledComponents/IconComponent';
import TextWrapper from './styledComponents/TextWrapper';
import ImageComponent from './styledComponents/ImageComponent';

@inject('appStore', 'assuranceStore')
@observer
export class Assurance extends Component<IAssuranceProps, IAssuranceState> {
	static configurationKey: 'assurance' = 'assurance';
	static styleKey: 'assurance' = 'assurance';
	constructor(props: IAssuranceProps) {
		super(props);
	}
	render() {
		const { className, assuranceStore, iconProps, imageProps, text } = this.props;

		if (!assuranceStore) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<IconWrapper>
					<IconComponent { ...iconProps } />
				</IconWrapper>
				<TextWrapper aria-live="polite">{ text }</TextWrapper>
				{ imageProps &&
					<ImageComponent { ...imageProps } />
				}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Assurance, 'assurance');

