import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { config } from '../../App/models/config';
import { IMfaMethodSelectorConfiguration } from '../models';

export class MfaMethodSelectorStore extends StoreBase {
	static componentKey: 'mfaMethodSelector' = 'mfaMethodSelector';

	@computed
	get configuration(): IMfaMethodSelectorConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(MfaMethodSelectorStore.componentKey);
		}
		return undefined;
	}

	@action
	async initialize(): Promise<void> {
	}

	@action startPushActivation = () => {
		const { appStore, mfaSetupStore } = this.storeContext;
		appStore.setStep(config.loginSteps.MFA);
		mfaSetupStore.setStep(config.mfaSteps.DUO_PUSH_SETUP);
		this.initializeMfaSetup();
	}

	@action startTextActivation = () => {
		const { appStore, mfaSetupStore } = this.storeContext;
		appStore.setStep(config.loginSteps.MFA);
		mfaSetupStore.setStep(config.mfaSteps.DUO_TEXT_SETUP);
		this.initializeMfaSetup();
	}

	@action startCallActivation = () => {
		const { appStore, mfaSetupStore } = this.storeContext;
		appStore.setStep(config.loginSteps.MFA);
		mfaSetupStore.setStep(config.mfaSteps.DUO_CALL_SETUP);
		this.initializeMfaSetup();
	}

	@action initializeMfaSetup = () => {
		const { mfaSetupStore } = this.storeContext;
		mfaSetupStore.initialize();
	}
}