import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IPasswordValidationConfiguration, IComplexityRules, IComplexityRulesValidationResult, IPasswordValidationSummary } from '../models';
import { IQueryOptions } from '../../../models/commonTypes';


const MINIMUM_CHARACTERS_TEMPLATE = 'Minimum {sub:minLength} characters';
const MAXIMUM_CHARACTERS_TEMPLATE = 'Maximum {sub:maxLength} characters';
const MINIMUM_LETTER_TEMPLATE = 'Must contain {sub:minLetters} letter';
const MINIMUM_LETTERS_TEMPLATE = 'Must contain {sub:minLetters} letters';
const MINIMUM_SPECIAL_CHARACTER_TEMPLATE = 'Must contain {sub:minPunctuation} special character';
const MINIMUM_SPECIAL_CHARACTERS_TEMPLATE = 'Must contain {sub:minPunctuation} special characters';
const MINIMUM_NUMBER_TEMPLATE = 'Must contain {sub:minNumbers} number';
const MINIMUM_NUMBERS_TEMPLATE = 'Must contain {sub:minNumbers} numbers';


export class PasswordValidationStore extends StoreBase {
	static componentKey: 'passwordValidation' = 'passwordValidation';
	@observable.ref complexityRules: IComplexityRules | undefined = undefined;
	@observable password = '';
	@observable confirmPassword = '';

	@computed
	get configuration(): IPasswordValidationConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(PasswordValidationStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {
		const { appStore } = this.storeContext;
		const loadingKey = 'PasswordValidationStore.initialize';
		appStore.startLoading(loadingKey);
		this.complexityRules = await this.retrieveComplexityRules();
		appStore.stopLoading(loadingKey);
	}

	async retrieveComplexityRules() {
		const resetToken = this.resetToken;
		const { kurtosysApiStore } = this.storeContext;
		const overrideOptions = {
			body: {
				passwordRequestId: resetToken,
			},
		};
		const rules = await kurtosysApiStore.passwordRequirements.execute(overrideOptions);

		// Remove the enforcePreviousPasswordCheck property being returned from the endpoint
		// as we do not need to do anything with it currently. It is being used in the portals.
		if (typeof (rules as any).enforcePreviousPasswordCheck !== 'undefined') {
			delete (rules as any).enforcePreviousPasswordCheck;
		}
		return rules;
	}

	@computed
	get resetToken() {
		const { setPasswordStore } = this.storeContext;
		return setPasswordStore.resetToken;
	}



	maxLength = (max: number) => (value: string) => value.length <= max;

	minLength = (min: number) => (value: string) => value.length >= min;

	minNumbers = (min: number) => (value: string) => value.replace(/[^\d]/gi, '').length >= min;

	minLetters = (min: number) => (value: string) => value.replace(/[^a-zA-Z]/gi, '').length >= min;

	minPunctuation = (min: number) => (value: string) => {
		const validPunctuation = '!"#$%&\'()* +,-./:;<=>?@[]^_`{|}~';
		return value.split('').filter(char => validPunctuation.includes(char)).length >= min;
	}

	requireUppercaseAndLowercase = (enabled: boolean) => (value: string) => {
		return !enabled || (/[A-Z]/.test(value) && /[a-z]/.test(value));
	}

	limitCharacterRepetition = (enabled: boolean, max: number = 2) => (value: string) => {
		if (!enabled) {
			return true;
		}
		let previousLetter = '';
		let count = 0;
		for (const char of value) {
			if (char === previousLetter) {
				count++;
			}
			else {
				previousLetter = char;
				count = 1;
			}
			if (count > max) {
				return false;
			}
		}
		return true;
	}

	get validators() {
		return {
			minLength: this.minLength,
			maxLength: this.maxLength,
			minLetters: this.minLetters,
			minPunctuation: this.minPunctuation,
			minNumbers: this.minNumbers,
			requireUppercaseAndLowercase: this.requireUppercaseAndLowercase,
			limitCharacterRepetition: this.limitCharacterRepetition,
		};
	}

	validatePassword = (password: string): IComplexityRulesValidationResult => {
		const summary: IComplexityRulesValidationResult = {};
		if (this.complexityRules) {
			for (const rule in this.complexityRules) {
				if ((this.validators as any)[rule]) {
					summary[rule] = (this.validators as any)[rule]((this.complexityRules as any)[rule])(password);
				}
			}
		}
		return summary;
	}

	@computed
	get messages() {
		if (this.complexityRules) {

			const minLettersTemplate = this.complexityRules.minLetters === 1 ? MINIMUM_LETTER_TEMPLATE : MINIMUM_LETTERS_TEMPLATE;
			const minPunctuationTemplate = this.complexityRules.minPunctuation === 1 ? MINIMUM_SPECIAL_CHARACTER_TEMPLATE : MINIMUM_SPECIAL_CHARACTERS_TEMPLATE;
			const minNumberTemplate = this.complexityRules.minNumbers === 1 ? MINIMUM_NUMBER_TEMPLATE : MINIMUM_NUMBERS_TEMPLATE;

			return {
				minLength: this.buildAndExecuteQuery('minLength', MINIMUM_CHARACTERS_TEMPLATE, this.complexityRules.minLength),
				maxLength: this.buildAndExecuteQuery('maxLength', MAXIMUM_CHARACTERS_TEMPLATE, this.complexityRules.maxLength),
				minLetters: this.buildAndExecuteQuery('minLetters', minLettersTemplate, this.complexityRules.minLetters),
				minPunctuation: this.buildAndExecuteQuery('minPunctuation', minPunctuationTemplate, this.complexityRules.minPunctuation),
				minNumbers: this.buildAndExecuteQuery('minNumbers', minNumberTemplate, this.complexityRules.minNumbers),
				requireUppercaseAndLowercase: `Must contain upper and lower case`,
				limitCharacterRepetition: `Should not repeat the same character 3 times consecutively`,
				match: `Passwords must match`,
			};
		}
	}

	buildAndExecuteQuery(queryId: string, template: string, value: any): string {
		const { appStore } = this.storeContext;
		const query: IQueryOptions = {
			queryOptionsType: 'none',
			options: {
				none: {
					value: template,
					subQueries: [{
						queryId,
						value,
						queryOptionsType: 'none',
					}],
				},
			},
		};
		return appStore.getQueryValue(query);
	}

	@computed
	get passwordCriteria() {
		const messages: IPasswordValidationSummary[] = [];
		const summary = this.validatePassword(this.password);
		if (this.complexityRules) {
			for (const rule in this.complexityRules) {
				if ((this.complexityRules as any)[rule]) {
					messages.push({
						rule,
						valid: !!summary[rule],
						message: (this.messages && (this.messages as any)[rule]) || '',
					});
				}
			}
		}

		messages.push({
			rule: 'match',
			valid: this.password !== '' && this.password === this.confirmPassword,
			message: 'Passwords must match',
		});
		return messages;
	}

	@computed
	get isValid() {
		return this.passwordCriteria.every(m => m.valid);
	}

	@computed
	get title(): string {
		const { translationStore } = this.storeContext;
		return translationStore.translate((this.configuration && this.configuration.title) || 'Password criteria');
	}
}

