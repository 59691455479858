import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFeedbackConfiguration, IFeedbackStepConfiguration, FeedbackType } from '../models';
import { config } from '../../App/models/config';
import { TLoginStep } from '../../../models/app/TLoginStep';


const DEFAULT_STEP_ACCOUNT_LOCKED: IFeedbackStepConfiguration = {
	text: {
		queryOptionsType: 'none',
		value: 'Your account has been locked due to too many failed login attempts. Please contact your administrator.',
	},
	type: FeedbackType.error,
};

const DEFAULT_STEP_CODE_INVALID: IFeedbackStepConfiguration = {
	text: {
		queryOptionsType: 'none',
		value: 'The code you provided has not been recognised or has expired. Please contact your administrator.',
	},
	type: FeedbackType.error,
};

const DEFAULT_STEP_ERROR: IFeedbackStepConfiguration = {
	text: {
		queryOptionsType: 'none',
		value: 'There was an error with your login. Please try again or contact your administrator.',
	},
	type: FeedbackType.error,
};

const DEFAULT_STEP_USER_SUCCESS: IFeedbackStepConfiguration = {
	text: {
		queryOptionsType: 'none',
		value: 'You have successfully {sub:action}.',
		subQueries: [
			{
				queryOptionsType: 'response',
				queryId: 'action',
				key: 'passwordMode',
				transforms: [
					{
						transformOptionsType: 'alias',
						aliases: [
							{
								source: 'password',
								replacement: 'reset your password',
							},
							{
								source: 'registration',
								replacement: 'registered',
							},
						],
					},
				],
			},
		],
	},
	type: FeedbackType.success,
};

const DEFAULT_STEP_MFA_SUCCESS: IFeedbackStepConfiguration = {
	text: {
		queryOptionsType: 'none',
		value: 'You have successfully {sub:action}.',
		subQueries: [
			{
				queryOptionsType: 'response',
				queryId: 'action',
				key: 'mfaMethod',
				transforms: [
					{
						transformOptionsType: 'alias',
						aliases: [
							{
								source: 'push',
								replacement: 'setup push authentication',
							},
							{
								source: 'call',
								replacement: 'setup call authentication',
							},
							{
								source: 'text',
								replacement: 'setup text authentication',
							},
						],
					},
				],
			},
		],
	},
	type: FeedbackType.success,
};

export class FeedbackStore extends StoreBase {
	static componentKey: 'feedback' = 'feedback';

	@computed
	get configuration(): IFeedbackConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FeedbackStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get currentStep(): TLoginStep | undefined {
		const { appStore } = this.storeContext;
		const { currentStep } = appStore;
		return currentStep;
	}

	@computed
	get stepConfiguration(): IFeedbackStepConfiguration | undefined {
		const { ACCOUNT_LOCKED, CODE_INVALID, ERROR, USER_SUCCESS, MFA_SUCCESS } = config.loginSteps;
		const configuration = this.configuration;

		const { steps = {} } = configuration || {};
		let response: IFeedbackStepConfiguration | undefined;
		switch (this.currentStep) {
			case ACCOUNT_LOCKED:
				response = steps.accountLocked || DEFAULT_STEP_ACCOUNT_LOCKED;
				break;
			case CODE_INVALID:
				response = steps.codeInvalid || DEFAULT_STEP_CODE_INVALID;
				break;
			case ERROR:
				response = steps.error || DEFAULT_STEP_ERROR;
				break;
			case USER_SUCCESS:
				response = steps.userSuccess || DEFAULT_STEP_USER_SUCCESS;
				break;
			case MFA_SUCCESS:
				response = steps.MFASuccess || DEFAULT_STEP_MFA_SUCCESS;
				break;
			default:
				break;
		}
		return response;

	}

	@action
	back = () => {
		const { messageStore, userLoginStore, appStore } = this.storeContext;
		messageStore.clearError();
		userLoginStore.clear();
		appStore.setStep(config.loginSteps.USER_LOGIN);
	}

	@computed
	get backButtonText() {
		return (this.stepConfiguration && this.stepConfiguration.backButtonText) || 'Back';
	}

	@computed
	get text() {
		const queryOptions = (this.stepConfiguration && this.stepConfiguration.text);
		if (queryOptions) {
			const { queryStore } = this.storeContext;
			const text = queryStore.query(queryOptions);
			if (text) {
				return text;
			}
		}
		return 'No text available';
	}

	@computed
	get icon() {
		return (this.stepConfiguration && this.stepConfiguration.icon) || '';
	}

	@computed
	get type(): FeedbackType {
		const providedType = this.stepConfiguration && this.stepConfiguration.type;
		if (providedType) {
			return providedType;
		}
		const { ACCOUNT_LOCKED, CODE_INVALID, ERROR, USER_SUCCESS, MFA_SUCCESS } = config.loginSteps;
		switch (this.currentStep) {
			case ERROR:
			case CODE_INVALID:
			case ACCOUNT_LOCKED:
				return FeedbackType.error;
			case USER_SUCCESS:
				return FeedbackType.success;
			case MFA_SUCCESS:
				return FeedbackType.success;
			default:
				break;
		}
		return FeedbackType.info;
	}
}