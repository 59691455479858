import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IMfaSetupConfiguration } from '../models';
import { config } from '../../App/models/config';
import { TMfaStep } from '../../../models/app/TMfaStep';
import { utils } from '@kurtosys/ksys-app-template';

const isNullOrEmpty = utils.typeChecks.isNullOrEmpty;

export enum MfaMethods {
	push = 'PUSH',
	call = 'CALL',
	text = 'TEXT',
}

export enum MfaStep {
	pushSetup = 'PUSH_SETUP',
	backupSetup = 'BACKUP_SETUP',
}

export class MfaSetupStore extends StoreBase {
	static componentKey: 'mfaSetup' = 'mfaSetup';
	@observable step: TMfaStep = config.mfaSteps.DUO_PUSH_SETUP;

	@computed
	get headingText() {
		return (this.configuration && this.configuration.headingText) || 'Two-Factor Authentication';
	}

	@computed
	get headingProps() {
		const heading = (this.configuration && this.configuration.heading);
		if (heading && isNullOrEmpty(heading.title)) {
			heading.title = this.headingText;
		}
		return heading || {
			title: this.headingText,
			level: 1,
		};
	}

	@computed
	get backButtonIcon() {
		return (this.configuration && this.configuration.backButtonIcon) || {
			asset: 'base.icons.backArrow',
			accessibilityText: 'Back to two factor method selection',
			addAccessibleText: false,
		};
	}

	@computed
	get codeValidation() {
		const { setPasswordStore } = this.storeContext;
		return setPasswordStore.codeValidation;
	}

	@computed
	get enabled2FAMethods(): string[] {
		// TODO: Remove?
		const methods = (this.codeValidation && this.codeValidation.enabled2FAMethods) || [];
		return methods;
	}

	@computed
	get isPushEnabled(): boolean {
		return this.step === config.mfaSteps.DUO_PUSH_SETUP || this.step === config.mfaSteps.DUO_PUSH_CONFIRM;
	}

	@computed
	get isCallEnabled(): boolean {
		return this.step === config.mfaSteps.DUO_CALL_SETUP;
	}

	@computed
	get isTextEnabled(): boolean {
		return this.step === config.mfaSteps.DUO_TEXT_SETUP;
	}

	@computed
	get configuration(): IMfaSetupConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(MfaSetupStore.componentKey);
		}
	}

	@action
	setStep = (step: TMfaStep) => {
		this.step = step;

		// TODO: confirm that success works as expected 
		// const { appStore } = this.storeContext;
		// appStore.setStep(config.loginSteps.USER_SUCCESS);
	}

	@action
	success = (method: MfaMethods) => {
		const { appStore, queryStore } = this.storeContext;
		const { context = {} } = queryStore;
		const response = {
			...(context.response || {}),
			mfaMethod: method.toLowerCase(),
		};
		queryStore.context = {
			...context,
			response,
		};
		queryStore.responseContext = response;
		appStore.setStep(config.loginSteps.MFA_SUCCESS);
	}

	@action
	async initialize(): Promise<void> {
		if (this.isPushEnabled) {
			const { mfaSetupPushStore } = this.storeContext;
			mfaSetupPushStore.initialize();
		}
		else if (this.isCallEnabled || this.isTextEnabled) {
			const { mfaSetupBackupStore } = this.storeContext;
			mfaSetupBackupStore.initialize();
		}
		else {
			const { appStore } = this.storeContext;
			appStore.setStep(config.loginSteps.USER_SUCCESS);
		}
	}


}