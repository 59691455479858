import { utils } from '@kurtosys/ksys-app-template';
import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IAssuranceConfiguration, IAssuranceProps } from '../models';

const BASE64_PNG_PREFIX = 'data:image/png;base64,';

export class AssuranceStore extends StoreBase {
	static componentKey: 'assurance' = 'assurance';
	@observable public assuranceMessage = '';
	@observable public assuranceImageId = '';
	@observable public assuranceImage = '';
	@observable public imageRequired: boolean = false;
	@observable public messageRequired: boolean = false;

	@computed
	get configuration(): IAssuranceConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(AssuranceStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@action
	setAssurance = (response: any) => {
		if (response) {
			this.imageRequired = response.assuranceImageEnabled;
			this.messageRequired = response.assuranceMessageEnabled;
			this.assuranceMessage = response.message;
			this.assuranceImageId = response.imageId;
			this.assuranceImage = BASE64_PNG_PREFIX + response.image;
		}
	}

	@computed
	get showAssuranceBox(): boolean {
		return (this.imageRequired && !utils.typeChecks.isNullOrEmpty(this.assuranceImageId)) ||
			(this.messageRequired && !utils.typeChecks.isNullOrEmpty(this.assuranceMessage));
	}

	@action
	clear = () => {
		this.assuranceMessage = '';
		this.assuranceImageId = '';
		this.imageRequired = false;
		this.messageRequired = false;
		// this.newAssuranceMessage = '';
	}

	@computed
	get assuranceProps(): IAssuranceProps {
		const props: IAssuranceProps = {
			iconProps: {
				asset: 'base.icons.message',
			},
		};
		if (this.imageRequired) {
			props.imageProps = {
				src: this.assuranceImage,
			};
		}

		if (this.messageRequired) {
			props.text = this.assuranceMessage;
		}
		return props;
	}
}