import { StoreContext } from '../../../configuration/StoreContext';
import { observable, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { utils } from '@kurtosys/ksys-app-template';

export class MessageStore extends StoreBase {
	@observable public errorText = '';
	@observable public infoText = '';

	constructor(storeContext: StoreContext) {
		super(storeContext);
	}

	async initialize() { }

	@action
	setErrorText = (text: string) => {
		this.errorText = text;
	}

	@action
	setInfoText = (text: string) => {
		this.infoText = text;
	}

	@action
	clearError = () => {
		this.errorText = '';
	}

	@action
	clearInfo = () => {
		this.infoText = '';
	}

	@action
	clearAll = () => {
		this.clearError();
		this.clearInfo();
	}

	@computed
	get hasErrorText(): boolean {
		return !utils.typeChecks.isNullOrEmpty(this.errorText);
	}

}