import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed, observable, action } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { ICardProps } from '@kurtosys/ksys-app-components/dist/components/base/Card/models';
import { Manifest } from '../../../configuration/Manifest';
import { TAction } from '../../../models/commonTypes';
import { config } from '../models/config';
import { utils } from '@kurtosys/ksys-app-template/dist';
import { TGetUserByTokenResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TGetUserByTokenResponseBody';
import { TMfaStep } from '../../../models/app/TMfaStep';
import { TLoginStep } from '../../../models/app/TLoginStep';
/* [Component: appStoreComponentImport] */

export class AppStore extends AppStoreBase {
	@computed
	get loading(): boolean {
		return this.loadingKeys.length > 0;
	}
	@observable public currentStep: TLoginStep | undefined;
	@observable public currentMfaStep: TMfaStep | undefined;
	@observable public stepHistory: TLoginStep[] = [];
	@observable.ref public user: TGetUserByTokenResponseBody | undefined;
	@observable.ref loadingKeys: string[] = [];

	hasStepInHistory = (step: TLoginStep) => {
		return (this.stepHistory || []).includes(step);
	}

	@action
	getUser = async () => {
		if (!this.user) {
			const { kurtosysApiStore } = this.storeContext;
			this.user = await kurtosysApiStore.getUserByToken.execute();
		}
		return this.user;
	}

	@action
	startLoading = (key: string) => {
		this.loadingKeys = [...this.loadingKeys, key];
	}

	@action
	stopLoading = (key: string) => {
		const firstIndex = this.loadingKeys.indexOf(key);
		this.loadingKeys = this.loadingKeys.filter((value, index) => {
			return index !== firstIndex;
		});
	}

	@action
	clearLoadingKeys = () => {
		this.loadingKeys = [];
	}

	public redirectURL = '/';

	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
	}

	@computed
	get isInternalBypass(): boolean {
		return window.location.search.indexOf('internalBypass') > -1;
	}

	@action
	async refreshRedirectUrl() {
		if (this.redirectIncludesLoggedInUser && !this.user) {
			try {
				await this.getUser();
			}
			catch (ex) {
				console.warn(ex);
			}
		}
		this.redirectURL = this.getRedirectURL();
	}

	@action
	async customInitializeAfter() {
		this.redirectURL = this.getRedirectURL();
		const { loginStrategySelectorStore, setPasswordStore, mfaSetupPushStore } = this.storeContext;
		if (this.isInternalBypass) {
			this.setStep(config.loginSteps.USER_LOGIN);
		}
		else {
			loginStrategySelectorStore.getLoginStrategies();
		}
		if (this.explicitStep) {
			this.setStep(this.explicitStep);
		}
		if (this.currentStep && this.currentStep === config.loginSteps.SET_PASSWORD) {
			setPasswordStore.initialize();
		}
		if (this.currentStep && this.currentStep === config.loginSteps.MFA) {
			await setPasswordStore.initialize();
			mfaSetupPushStore.initialize();
		}
	}

	@computed
	get hasData(): boolean {
		// TODO: Each Application should put custom show logic here: "return this.storeContext[component store].hasData;"
		return true;
	}

	@computed
	get loginStepValues(): string[] {
		return Object.keys(config.loginSteps).map((key) => {
			return config.loginSteps[key];
		});
	}

	@computed
	get explicitStep(): TLoginStep | undefined {
		const explicitStep = this.appParamsHelper && this.appParamsHelper.rawAppParams && this.appParamsHelper.rawAppParams.step;
		// Check that the explicitStep provided is a valid login step
		if (explicitStep && this.loginStepValues.includes(explicitStep)) {
			return explicitStep;
		}
		return undefined;
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get cardProps(): ICardProps | undefined {
		const cardProps = this.appComponentConfiguration && this.appComponentConfiguration.cardProps;
		return this.mergeQueriesAndProps(cardProps);
	}

	@action
	public setStep = (stepName: TLoginStep) => {
		if (this.currentStep) {
			this.stepHistory = [...(this.stepHistory || []), this.currentStep];
		}
		this.currentStep = stepName;
		if (stepName === config.loginSteps.SETUP_ASSURANCES) {
			const { setupAssurancesStore } = this.storeContext;
			setupAssurancesStore.initialize();
		}
	}

	@computed
	get disabledActions() {
		const disabledActions = this.appComponentConfiguration && this.appComponentConfiguration.disabledActions;
		return new Set<TAction>(disabledActions);
	}

	public isActionEnabled = (action: TAction) => {
		return !this.disabledActions.has(action);
	}

	@action
	public clear = () => {
		const { messageStore, userLoginStore, assuranceStore, loginStrategySelectorStore } = this.storeContext;
		if (loginStrategySelectorStore.loginStrategies.length === 0) {
			this.setStep(config.loginSteps.USER_LOGIN);
		}
		else {
			this.setStep(config.loginSteps.LOGIN_STRATEGY_SELECTOR);
		}
		this.clearLoadingKeys();
		userLoginStore.username = '';
		userLoginStore.password = '';
		messageStore.clearAll();
		assuranceStore.clear();
		// this.complete = false;
	}

	@computed
	get redirectIncludesLoggedInUser(): boolean {
		const { userLoginStore } = this.storeContext;
		const redirectQuery = userLoginStore.configuration && userLoginStore.configuration.redirectUrl;
		return !utils.typeChecks.isNullOrEmpty(redirectQuery) && JSON.stringify(redirectQuery).includes(`"queryOptionsType":"loggedInUser"`);
	}

	@computed
	get configuredRedirectUrl(): string | undefined {
		const { userLoginStore } = this.storeContext;
		const overrideExecutionOptions: any = {};
		if (this.redirectIncludesLoggedInUser) {
			overrideExecutionOptions.context = {
				loggedInUser: this.user,
			};
		}
		const redirectUrl = this.getQueryValue(userLoginStore.configuration && userLoginStore.configuration.redirectUrl, undefined, overrideExecutionOptions);
		return redirectUrl;
	}

	private getRedirectURL = () => {
		// get the configured url and set as default if no query string redirect is found.
		const defaultUrl = this.configuredRedirectUrl || '/';

		// Find redirect parameter
		const mapping = {
			key: 'redirect',
		};
		const redirect = utils.url.getQueryStringValue(mapping, window.location.search);

		// Can redirect if the redirect has the same domain as the URL in addittion to usual redirects
		if (redirect && ((!redirect.startsWith('//') && redirect.startsWith('/')) || redirect.startsWith(window.location.origin))) {
			return redirect.replace(/\+/g, ' ');
		}

		return defaultUrl;
	}

	public redirectTo(redirectTo = '/') {
		const loadingKey = 'redirectTo';
		this.startLoading(loadingKey);
		window.location.href = redirectTo;
	}

}