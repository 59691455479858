import React, { Component } from 'react';
import {
	IForgotUsernameProps,
	IForgotUsernameState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ButtonFlat from '../App/styledComponents/ButtonFlat';
import IconInput from '../App/styledComponents/IconInput';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import SuccessMessage from '../App/styledComponents/SuccessMessage';
import Label from './styledComponents/Label';

@inject('appStore', 'forgotUsernameStore', 'messageStore')
@observer
export class ForgotUsername extends Component<IForgotUsernameProps, IForgotUsernameState> {
	static configurationKey: 'forgotUsername' = 'forgotUsername';
	static styleKey: 'forgotUsername' = 'forgotUsername';
	constructor(props: IForgotUsernameProps) {
		super(props);
	}
	render() {
		const { className, forgotUsernameStore, messageStore } = this.props;

		if (!forgotUsernameStore || !messageStore) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<ButtonFlat onClick={ forgotUsernameStore.backToUserLogin } iconProps={ { asset: 'base.icons.backArrow' } } data-qa-ref="forgotUsernameBackButton" iconPosition={ 'left' }>
					{ forgotUsernameStore.backButtonText }
				</ButtonFlat>
				<form spellCheck={ false } autoSave="false" onSubmit={ (e) => {
					e.preventDefault();
					return false;
				} }>
					{ forgotUsernameStore.labelText && <Label htmlFor="emailInput" value={ forgotUsernameStore.labelText } /> }
					<IconInput
						id="emailInput"
						name="email"
						aria-label={ !forgotUsernameStore.labelText && forgotUsernameStore.placeholderText }
						placeholder={ forgotUsernameStore.placeholderText }
						onKeyDown={ forgotUsernameStore.onSubmitRequestUsernameRequest }
						autoFocus
						onChange={ (event: React.ChangeEvent<HTMLInputElement>) => forgotUsernameStore.updateEmail(event.target.value) }
						value={ forgotUsernameStore.email }
						iconProps={ { asset: 'base.iconInput.personOutline' } }
						hasError={ messageStore.hasErrorText }
						data-qa-ref="forgotUsernameInput"
						required
						aria-required="true"
						autoComplete="email"
					/>
					<ErrorMessage data-qa-ref="forgotUsernameError" >
						{ messageStore.errorText }
					</ErrorMessage>
					<SuccessMessage data-qa-ref="forgotUsernameInfo">
						{ messageStore.infoText }
					</SuccessMessage>
					<ButtonRaised onClick={ forgotUsernameStore.onSubmitRequestUsernameRequest } data-qa-ref="requestForgotUsernameButton">
						{ forgotUsernameStore.forgotUsernameButtonText }
					</ButtonRaised>
				</form>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(ForgotUsername, 'forgotUsername');

