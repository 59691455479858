import React, { Component } from 'react';
import {
	ISetPasswordProps,
	ISetPasswordState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import PasswordValidation from '../PasswordValidation';
import Wrapper from './styledComponents/Wrapper';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import IconInput from '../App/styledComponents/IconInput';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import InfoMessage from '../App/styledComponents/InfoMessage';
import Heading from './styledComponents/Heading';
import Label from './styledComponents/Label';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';

@inject('appStore', 'setPasswordStore', 'messageStore')
@observer
export class SetPassword extends Component<ISetPasswordProps, ISetPasswordState> {
	static configurationKey: 'setPassword' = 'setPassword';
	static styleKey: 'setPassword' = 'setPassword';
	constructor(props: ISetPasswordProps) {
		super(props);
	}
	render() {
		const { className, setPasswordStore, messageStore } = this.props;

		if (!setPasswordStore || !messageStore) {
			return null;
		}
		const {
			heading,
			text,
			password,
			confirmPassword,
			passwordPlaceholderText,
			passwordLabelText,
			confirmPasswordPlaceholderText,
			confirmPasswordLabelText,
			setPassword,
			setConfirmPassword,
			handleUpdateClick,
			updatePasswordText,
			isValid,
		} = setPasswordStore;
		return (
			<Wrapper className={ className }>
				{ heading && <Heading data-qa-ref="setPasswordHeading">
					<Translate>{ heading }</Translate>
				</Heading> }
				<InfoMessage data-qa-ref="setPasswordInfo1">
					{ messageStore.infoText }
				</InfoMessage>
				{ text && <InfoMessage data-qa-ref="setPasswordInfo1">
					{ text }
				</InfoMessage> }
				<form spellCheck={ true } autoSave="false" onSubmit={ (e) => {
					e.preventDefault();
					return false;
				} }>
					{ passwordLabelText && <Label htmlFor="passwordInput" value={ passwordLabelText } /> }
					<IconInput
						id="passwordInput"
						name="password"
						aria-label={ !passwordLabelText && passwordPlaceholderText }
						placeholder={ passwordPlaceholderText }
						type="password"
						onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value) }
						value={ password }
						iconProps={ { asset: 'base.iconInput.fingerPrint' } }
						hasError={ messageStore.hasErrorText }
						data-qa-ref="passwordInput"
						required
						aria-required="true"
						autoComplete="new-password"
					/>
					{ confirmPasswordLabelText && <Label htmlFor="confirmPassword" value={ confirmPasswordLabelText } /> }
					<IconInput
						id="confirmPassword"
						name="confirmPassword"
						aria-label={ !confirmPasswordLabelText && confirmPasswordPlaceholderText }
						placeholder={ confirmPasswordPlaceholderText }
						type="password"
						onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value) }
						value={ confirmPassword }
						iconProps={ { asset: 'base.iconInput.fingerPrint' } }
						hasError={ messageStore.hasErrorText }
						data-qa-ref="confirmPasswordInput"
						required
						aria-required="true"
						autoComplete="new-password"
					/>
					<PasswordValidation />
					<ErrorMessage data-qa-ref="setPasswordError">
						{ messageStore.errorText }
					</ErrorMessage>
					<ButtonRaised disabled={ !isValid } onClick={ handleUpdateClick } data-qa-ref="requestForgotPasswordButton">
						{ updatePasswordText }
					</ButtonRaised>
				</form>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SetPassword, 'setPassword');

